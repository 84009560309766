const cookiesAdooreiPrefix = 'adoo-';
export function getQuery() {
    const params = new URLSearchParams(window.location.search);
    return {
        ...Object.fromEntries(params),
    };
}
export function saveQueryOnCookies() {
    const params = getQuery();
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    Object.keys(params).forEach((key) => {
        document.cookie = `${cookiesAdooreiPrefix}${key}=${params[key]}; expires=${expires.toUTCString()}; path=/`;
    });
}
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookiesAdooreiPrefix}${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
export function getAllAdooCookies() {
    return document.cookie
        .split(';')
        .filter((cookie) => cookie.includes(cookiesAdooreiPrefix))
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => {
            acc[key.trim().replace(cookiesAdooreiPrefix, '')] = value;
            return acc;
        }, {});
}
export function queryFormatter(query) {
    return Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&');
}