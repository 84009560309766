import { createApp } from 'vue'
// import router from '@/router/router.js'
import router from './router';
import App from '@/App.vue'
import '@/styles/global.scss'


const app = createApp(App)
app.use(router)
.mount('#app')
