
<template>
    <main>
        <RouterView />
    </main>
</template>
<script>
import { saveQueryOnCookies } from '@/utils/helpers';

export default {
    name: 'App',
    mounted() {
        // start load intercom
        window.intercomSettings = {
            app_id: 'tethda3n',
        };

        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/tethda3n'
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/tethda3n';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    
        // salva a query na url em cookies
        saveQueryOnCookies();
    },
};
</script>