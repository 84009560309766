import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Home',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/termos-de-uso',
        name: 'Terms',
        component: () => import('@/views/TermsView.vue')
    },
    {
        path: '/politica-de-privacidade',
        name: 'Privacy',
        component: () => import('@/views/PolicyPrivacy.vue')
    },
    {
        path: '/informacao-google',
        name: 'InfoGoogle',
        component: () => import('@/views/InfoGoogleApi.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router
